import styled from "styled-components";
import Header from "../../components/header/Header";
import Colors from "../../shared/colors";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import phoneapp from "../../images/trainboapp.png";
import appstorebutton from "../../images/appstorebutton.svg";
import { useEffect } from "react";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Download = () => {
  function redirectToExternalWebsite() {
    // Redirect to example.com
    window.location.href =
      "https://apps.apple.com/pl/app/trainbo/id6477769489?platform=iphone";
  }

  useEffect(() => {
    redirectToExternalWebsite();
  }, []);
  return <></>;
};

export default Download;
