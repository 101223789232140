import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../../images/trainbo-logo.svg";
import { IoLogoInstagram } from "react-icons/io5";
import Colors from "../../shared/colors";

const Navbar = styled.div`
  width: 100%;
  display: flex;
  padding: 25px 25px 10px 25px;
  justify-content: space-between;
`;

const Logo = styled.img`
  width: 100px;
`;

const InstaIcon = styled(IoLogoInstagram)`
  width: 30px;
  font-size: 25px;
  color: ${Colors.secondaryText};

  &:hover {
    color: ${Colors.primary};
  }
`;

const Header = () => {
  return (
    <Navbar>
      <Link to='/'>
        <Logo src={logo} alt='trainbo' />
      </Link>

      <a
        href='https://www.instagram.com/trainboapp/'
        target='_blank'
        rel='noreferrer'>
        <InstaIcon />
      </a>
    </Navbar>
  );
};

export default Header;
