import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import Router from "./routes/Router";
import CacheBuster from "react-cache-buster";
import packageJson from "../package.json";

import authReducers from "./store/reducers/auth";

const rootReducers = combineReducers({
  auth: authReducers,
});

const store = createStore(rootReducers, applyMiddleware(ReduxThunk));

function App() {
  const isProduction = false;
  return (
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
    >
      <Provider store={store}>
        <Router />
      </Provider>
    </CacheBuster>
  );
}

export default App;
