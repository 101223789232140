import styled from "styled-components";
import Header from "../../components/header/Header";
import Colors from "../../shared/colors";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import phoneapp from "../../images/trainboapp.png";
import appstorebutton from "../../images/appstorebutton.svg";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Box = styled.div`
  width: 100%;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 46px;
  font-weight: bold;
  line-height: 1.25;
  margin: 0;
  padding-bottom: 10px;
`;

const Text = styled.p`
  font-size: 22px;
  color: ${Colors.secondaryText};
  line-height: 1.625;
  margin: 0;
`;

const ImageBox = styled.div`
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`;

const Phone = styled.img`
  width: 485px;
`;

const Rules = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  padding: 10px 0;

  a {
    color: #fff;
    text-decoration: none;
    color: ${Colors.secondaryText};
    font-size: 12px;
    padding: 10px 10px 0 0;
  }
`;

const FeedBack = styled.div`
  color: ${Colors.secondaryText};
  padding: 10px;
  font-size: 16px;
`;

const AppStoreButton = styled.img`
  width: 150px;
`;

const AppStoreButtonBox = styled.div`
  margin-top: 10px;
  padding: 10px 0;
`;

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Train like you like - Trainbo</title>
        <link
          rel='icon'
          type='image/png'
          href='https://trainbo.com/images/favicon.png'
          sizes='16x16'
        />
      </Helmet>
      <Header />
      <Container>
        <Box>
          <Title>Train like you like</Title>
          <Text>
            Improve your gym experience and achieve better results by enjoying
            your workouts.
          </Text>
          <AppStoreButtonBox>
            <a href='https://apps.apple.com/pl/app/trainbo/id6477769489?platform=iphone'>
              <AppStoreButton src={appstorebutton} />
            </a>
          </AppStoreButtonBox>
          <FeedBack>Send your feedback: contact@trainbo.com</FeedBack>
          <Rules>
            <Link to='terms'>Terms & Condition</Link>
            <Link to='privacy'>Privacy Policy</Link>
          </Rules>
        </Box>
      </Container>
      <ImageBox>
        <Phone src={phoneapp} />
      </ImageBox>
    </>
  );
};

export default Home;
