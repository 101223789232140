import { BrowserRouter, Routes, Route } from "react-router-dom";

import ChangePassword from "../pages/auth/ChangePassword";
import DeleteAccount from "../pages/auth/DeleteAccount";
import Home from "../pages/home/Home";
import Terms from "../pages/terms/Terms";
import Privacy from "../pages/privacy/Privacy";
import Download from "../pages/download/Download";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='change-password/:token' element={<ChangePassword />} />
        <Route path='delete-account/:token' element={<DeleteAccount />} />
        <Route path='terms' element={<Terms />} />
        <Route path='privacy' element={<Privacy />} />
        <Route path='download' element={<Download />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
