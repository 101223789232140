import styled from "styled-components";
import Header from "../../components/header/Header";
import Colors from "../../shared/colors";
import { IoLogoInstagram } from "react-icons/io5";
import { Helmet } from "react-helmet";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Body = styled.div`
  max-width: 1000px;
  margin: auto;
`;

const Title = styled.h2``;

const Box = styled.div`
  margin-bottom: 30px;
`;

const Par = styled.p`
  margin-bottom: 10px;
`;

const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Trainbo - enjoy your workouts</title>
      </Helmet>
      <Header />
      <Container>
        <Body>
          <Box>
            <Title>Privacy Policy</Title>
            <Par>Updated at 2024-03-10</Par>
            <Par>
              Trainbo. ("we," "our," or "us") is committed to protecting your
              privacy. This Privacy Policy explains how your personal
              information is collected, used, and disclosed by Trainbo. This
              Privacy Policy applies to our application named Trainbo , and its
              associated subdomains (collectively, our "Service"). By accessing
              or using our Service, you signify that you have read, understood,
              and agree to our collection, storage, use, and disclosure of your
              personal information as described in this Privacy Policy and our
              Terms of Service. 
            </Par>
            <Par>
              BY INSTALLING, USING, REGISTERING TO OR OTHERWISE ACCESSING ANY
              APPS, YOU AGREE TO THIS PRIVACY POLICY AND GIVE AN EXPLICIT AND
              INFORMED CONSENT TO THE STORAGE, USE, AND PROCESSING OF YOUR
              PERSONAL DATA IN ACCORDANCE WITH THIS PRIVACY POLICY. IF YOU DO
              NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT INSTALL, USE,
              REGISTER TO OR OTHERWISE ACCESS ANY APPS. Trainbo reserves the
              right to modify this Privacy Policy at any time, so please review
              it frequently. If Trainbo makes material or significant changes to
              this Privacy Policy, the updates will be posted to the website at
              https://trainbo.com/privacy/. Any changes to this Privacy Policy
              will be effective upon their being posted on Trainbo's website.
              Your continued use of Apps will signify your acceptance of the
              changes to this Privacy Policy.
            </Par>
          </Box>

          <Box>
            <Title>Definitions and key terms</Title>
            <Par>For this Privacy Policy:</Par>
            <Par>
              <ul>
                <li>
                  <b>Cookie:</b> small amount of data generated by a website and
                  saved by your web browser. It is used to identify your
                  browser, provide analytics, remember information about you
                  such as your language preference or login information.
                </li>
                <li>
                  <b>Company:</b> when this policy mentions “Company,” “we,”
                  “us,” or “our,” it refers to Grzegorz Gołębiewski, Czerwony
                  Dwor 9, 80-383 Gdańsk that is responsible for your information
                  under this Privacy Policy.
                </li>
                <li>
                  <b>Country:</b> where Trainbo or the owners/founders of
                  Trainbo are based, in this case is Poland.
                </li>
                <li>
                  <b>Customer:</b> refers to the company, organization or person
                  that signs up to use the Trainbo Service to manage the
                  relationships with your consumers or service users.
                </li>
                <li>
                  <b>Device:</b> any internet connected device such as a phone,
                  tablet, computer or any other device that can be used to visit
                  Trainbo and use the services.
                </li>
                <li>
                  <b>IP address:</b> Every device connected to the Internet is
                  assigned a number known as an Internet protocol (IP) address.
                  These numbers are usually assigned in geographic blocks. An IP
                  address can often be used to identify the location from which
                  a device is connecting to the Internet.
                </li>
                <li>
                  <b>Personnel:</b> refers to those individuals who are employed
                  by Trainbo or are under contract to perform a service on
                  behalf of one of the parties.
                </li>
                <li>
                  <b>Personal Data:</b> any information that directly,
                  indirectly, or in connection with other information —
                  including a personal identification number — allows for the
                  identification or identifiability of a natural person.
                </li>
                <li>
                  <b>Service:</b> refers to the service provided by Trainbo as
                  described in the relative terms (if available) and on this
                  platform.
                </li>
                <li>
                  <b>Third-party service:</b> refers to advertisers, contest
                  sponsors, promotional and marketing partners, and others who
                  provide our content or whose products or services we think may
                  interest you.
                </li>
                <li>
                  <b>Website:</b> Trainbo’s site, which can be accessed via this
                  URL: https://trainbo.com
                </li>
                <li>
                  <b>You:</b> a person or entity that is registered with Trainbo
                  to use the Services.
                </li>
              </ul>
            </Par>
          </Box>
          <Box>
            <Title>What Information Do We Collect?</Title>
            <Par>
              <b>Personally Identifiable Information:</b>
            </Par>
            <Par>
              Trainbo may collect personal information that you voluntarily
              provide to us. This information personally identifies you and may
              include:
              <ul>
                <li>
                  Contact Information: Your name, email address, and phone
                  number.
                </li>
                <li>Demographic Information: Date of birth and gender.</li>
                <li>
                  Transaction Information: Billing details necessary for
                  processing transactions.
                </li>
                <li>
                  Health Information: Specific health-related data that you
                  choose to share, relevant to the services we provide.
                </li>
                <li>
                  User Activity: Information about tasks you complete or buttons
                  clicked on the Trainbo.
                </li>
                <li>
                  Personal Preferences: Details regarding your preferences and
                  interests as they relate to our services.
                </li>
                <li>
                  This data is collected to provide you with a tailored and
                  effective experience on the Trainbo.
                </li>
              </ul>
            </Par>
            <Par>
              <b>Non-Personally Identifiable Information:</b>
            </Par>
            <Par>
              We also collect information that does not personally identify you.
              This includes, but is not limited to:
              <ul>
                <li>
                  Device Information: Details about the device you use to access
                  our platform, such as the operating system, hardware model,
                  and unique device identifiers.
                </li>
                <li>
                  Usage Data: Information about how you interact with our
                  services, like the pages or features you use, the time spent
                  on these pages, and the frequency of your activities.
                </li>
                <li>
                  Location Data: General geographic location derived from your
                  IP address or more precise location data if you grant
                  permission.
                </li>
                <li>
                  Device and Network Information: Details about your device,
                  such as the media access control (MAC) address, browser type,
                  unique device identifiers, operating system, and IP address.
                </li>
                <li>
                  Internet Service Details: Information about your internet
                  service provider and the number of users on the Trainbo.
                </li>
                <li>
                  Usage Metrics: Time spent, number of sessions on the website,
                  and traffic patterns on the Trainbo.
                </li>
              </ul>
              <p>
                This information helps us to understand the usage patterns and
                preferences of our user base as a whole, allowing us to improve
                our services and deliver a more personalized experience.
              </p>
            </Par>
            <Par>
              <b>Use of Collected Information:</b>
            </Par>
            <Par>
              The information collected, both personally identifiable and
              non-personally identifiable, is crucial for Trainbo to offer a
              comprehensive and customized fitness experience. It allows us to
              develop and fine-tune features that meet your specific needs,
              improve the functionality of our platform, and provide you with
              relevant content and offers.
            </Par>
            <Par>
              Trainbo allows you to post information in public areas of the
              Teams App or share it with other users or Trainbo coaches. These
              contributions include, but are not limited to:
            </Par>
            <Par>
              Public Posts: Any information you choose to publish or display
              publicly on the Teams App. Shared Information: Any information you
              transmit to other users of the Teams App or third parties.
            </Par>
            <Par>
              <b>Risk Acknowledgment and Security Disclaimer:</b>
              <ul>
                <li>
                  User Risk: Your User Contributions are posted and transmitted
                  to others at your own risk. We remind you to be cautious about
                  what you share.
                </li>
                <li>
                  Security Limitations: While Trainbo implements robust security
                  measures, please be aware that no security system is
                  infallible.
                </li>
                <li>
                  Lack of Control Over Other Users: We cannot control the
                  actions of other users with whom you share your User
                  Contributions. Consequently, we cannot guarantee that your
                  User Contributions will not be viewed by unauthorized persons.
                </li>
              </ul>
            </Par>
          </Box>
          <Box>
            <Title>How Do We Use The Information We Collect?</Title>
            <Par>
              The Trainbo collects information about you through various
              methods, which are described below, to enhance your experience and
              improve our services:
              <b>Data Collection Methods:</b>
              <ul>
                <li>
                  Direct Information Provision: We collect information that you
                  provide to us directly, such as when you sign up on the Teams
                  App, and through usage of the Trainbo.
                </li>
                <li>
                  Device and Usage Information: We gather information from your
                  device when you access the Trainbo, including analytics to
                  track crash data and understand user engagement.
                </li>
                <li>
                  Analytics Tools: We employ third-party analytics services to
                  monitor user interaction with the Trainbo. These services help
                  us collect aggregated data and usage patterns.
                </li>
                <li>
                  Cookies: The Trainbo utilizes cookies, small data files stored
                  on your device, for record-keeping and to enhance user
                  experience. Cookies may be used for various purposes, such as
                  remembering your preferences or tracking your usage to improve
                  our services. While most web browsers accept cookies by
                  default, you can modify your browser settings to reject
                  cookies, though this might affect the functionality of the
                  Trainbo.
                </li>
                <li>
                  Web Beacons: We may use web beacons on the Trainbo and in
                  emails for tracking and monitoring user activity, auditing
                  purposes, or to improve platform performance.
                </li>
                <li>
                  Social Media Integration: The Trainbo integrates with social
                  media platforms and includes widgets for sharing and
                  interacting on networks like Facebook, Twitter, Spotify,
                  Instagram, or TikTok. These integrations may collect your IP
                  address and page visit information. Logging in through Google
                  or Facebook accounts may share your authentication information
                  with us, and your Trainbo activity may be shared with these
                  platforms.
                </li>
                <li>
                  Location Data: We collect both precise (like GPS data) and
                  imprecise location data. Precise data helps enable
                  location-based features, while imprecise data may be derived
                  from your IP address or other less specific sources.
                </li>
                <li>
                  Surveys and Promotions: Occasionally, we may conduct surveys
                  or offer promotions on the Trainbo, requiring personal
                  information. Participation is voluntary, and the information
                  gathered is used for enhancing your user experience and
                  improving the Trainbo.
                </li>
                <li>
                  Communication Monitoring: We collect information about your
                  interactions and communications on the Trainbo, including
                  those with Trainbo Coaches. This may include message counts,
                  timestamps, and content. We also use this information for
                  sending you related messages or push notifications. You can
                  opt out of push notifications through your device settings.
                </li>
                <li>
                  Service Provision: The personal information collected from you
                  is used to deliver the services and information you request on
                  the Trainbo. This includes sharing relevant user information
                  with a Trainbo Coach (excluding sensitive data like personal
                  email or credit card details).
                </li>
                <li>
                  Content and Offers: We use your personal information to
                  provide you with offers or content related to the Trainbo and
                  to facilitate transactions. If applicable, this includes
                  support and services for products or programs you register for
                  through the Trainbo.
                </li>
                <li>
                  Communication: Your email address and other contact
                  information are used for administrative purposes, customer
                  service, and addressing issues related to your use of the
                  Trainbo. We also use your contact details to send you offers
                  or marketing campaigns related to the Trainbo.
                </li>
                <li>
                  Platform Improvement: Information that does not personally
                  identify you is used to operate, maintain, enhance, and
                  provide all features and services on the Trainbo. This
                  includes using collected information to improve or develop new
                  products, services, or websites, conduct research and
                  analysis, and comply with legal obligations.
                </li>
                <li>
                  Data Storage: The information we collect, whether personal or
                  non-personal, may be stored by us or third parties engaged by
                  us.
                </li>
                <li>
                  Opting Out of Personal Information Provision: You have the
                  option to decline providing personal information on the
                  Trainbo. However, choosing not to share personal information
                  might restrict your ability to use certain features or
                  functionalities of the Trainbo.
                </li>
                <li>
                  Access and Modification: If you wish to access, amend, update,
                  or delete your personal information, please reach out to us
                  using the contact information provided below.
                </li>
              </ul>
            </Par>
          </Box>
          <Box>
            <Title>
              When do we use customer information from third parties?
            </Title>
            <Par>
              We receive some information from the third parties when you
              contact us. For example, when you submit your email address to us
              to show interest in becoming our customer, we receive information
              from a third party that provides automated fraud detection
              services to us. We also occasionally collect information that is
              made publicly available on social media websites. You can control
              how much of your information social media websites make public by
              visiting these websites and changing your privacy settings.
            </Par>
          </Box>
          <Box>
            <Title>
              Do we share the information we collect with third parties?
            </Title>
            <Par>
              We may share the information that we collect, both personal and
              non-personal, with third parties such as advertisers, contest
              sponsors, promotional and marketing partners, and others who
              provide our content or whose products or services we think may
              interest you. We may also share it with our current and future
              affiliated companies and business partners, and if we are involved
              in a merger, asset sale or other business reorganization, we may
              also share or transfer your personal and non-personal information
              to our successors-in-interest.
            </Par>
            <Par>
              We may engage trusted third party service providers to perform
              functions and provide services to us, such as hosting and
              maintaining our servers and our service, database storage and
              management, e-mail management, storage marketing, credit card
              processing, customer service and fulfilling orders for products
              and services you may purchase through our platform. We will likely
              share your personal information, and possibly some non-personal
              information, with these third parties to enable them to perform
              these services for us and for you.
            </Par>
            <Par>
              We may share portions of our log file data, including IP
              addresses, for analytics purposes with third parties such as web
              analytics partners, application developers, and ad networks. If
              your IP address is shared, it may be used to estimate general
              location and other technographics such as connection speed,
              whether you have visited the service in a shared location, and
              type of device used to visit the service. They may aggregate
              information about our advertising and what you see on the service
              and then provide auditing, research and reporting for us and our
              advertisers.
            </Par>
            <Par>
              We may also disclose personal and non-personal information about
              you to government or law enforcement officials or private parties
              as we, in our sole discretion, believe necessary or appropriate in
              order to respond to claims, legal process (including subpoenas),
              to protect our rights and interests or those of a third party, the
              safety of the public or any person, to prevent or stop any
              illegal, unethical, or legally actionable activity, or to
              otherwise comply with applicable court orders, laws, rules and
              regulations.
            </Par>
            
          </Box>
          <Box>
            <Title>
              Where and when is information collected from customers and end
              users?
            </Title>
            <Par>
              We will collect personal information that you submit to us. We may
              also receive personal information about you from third parties as.
              described above.
            </Par>
          </Box>
          <Box>
            <Title>How Do We Use Your Email Address?</Title>
            <Par>
              By submitting your email address on this app, you agree to receive
              emails from us. You can cancel your participation in any of these
              email lists at any time by clicking on the opt-out link or other
              unsubscribe option that is included in the respective email. We
              only send emails to people who have authorized us to contact them,
              either directly, or through a third party. We do not send
              unsolicited commercial emails, because we hate spam as much as you
              do. By submitting your email address, you also agree to allow us
              to use your email address for customer audience targeting on sites
              like Facebook, where we display custom advertising to specific
              people who have opted-in to receive communications from us. Email
              addresses submitted only through the order processing page will be
              used for the sole purpose of sending you information and updates
              pertaining to your order. If, however, you have provided the same
              email to us through another method, we may use it for any of the
              purposes stated in this Policy. Note: If at any time you would
              like to unsubscribe from receiving future emails, we include
              detailed unsubscribe instructions at the bottom of each email.
            </Par>
          </Box>
          <Box>
            <Title>
              Could my information be transferred to other countries?
            </Title>
            <Par>
              We are incorporated in Poland. Information collected via our
              website, through direct interactions with you, or from use of our
              help services may be transferred from time to time to our offices
              or personnel, or to third parties, located throughout the world,
              and may be viewed and hosted anywhere in the world, including
              countries that may not have laws of general applicability
              regulating the use and transfer of such data. To the fullest
              extent allowed by applicable law, by using any of the above, you
              voluntarily consent to the trans- border transfer and hosting of
              such information.
            </Par>
          </Box>
          <Box>
            <Title>
              Is the information collected through our service secure?
            </Title>
            <Par>
              We take precautions to protect the security of your information.
              We have physical, electronic, and managerial procedures to help
              safeguard, prevent unauthorized access, maintain data security,
              and correctly use your information. However, neither people nor
              security systems are foolproof, including encryption systems. In
              addition, people can commit intentional crimes, make mistakes or
              fail to follow policies. Therefore, while we use reasonable
              efforts to protect your personal information, we cannot guarantee
              its absolute security. If applicable law imposes any
              non-disclaimable duty to protect your personal information, you
              agree that intentional misconduct will be the standards used to
              measure our compliance with that duty.
            </Par>
          </Box>
          <Box>
            <Title>Can | update or correct my information?</Title>
            <Par>
              The rights you have to request updates or corrections to the
              information we collect depend on your relationship with us.
              Personnel may update or correct their information as detailed in
              our internal company employment policies. Customers have the right
              to request the restriction of certain uses and disclosures of
              personally identifiable information as follows. You can contact us
              in order to (1) update or correct your personally identifiable
              information, (2) change your preferences with respect to
              communications and other information you receive from us, or (3)
              delete the personally identifiable information maintained about
              you on our systems (subject to the following paragraph), by
              cancelling your account. Such updates, corrections, changes and
              deletions will have no effect on other information that we
              maintain, or information that we have provided to third parties in
              accordance with this Privacy Policy prior to such update,
              correction, change or deletion. To protect your privacy and
              security, we may take reasonable steps (such as requesting a
              unique password) to verify your identity before granting you
              profile access or making corrections. You are responsible for
              maintaining the secrecy of your unique password and account
              information at all times. You should be aware that it is not
              technologically possible to remove each and every record of the
              information you have provided to us from our system. The need to
              back up our systems to protect information from inadvertent loss
              means that a copy of your information may exist in a non-erasable
              form that will be difficult or impossible for us to locate.
              Promptly after receiving your request, all personal information
              stored in databases we actively use, and other readily searchable
              media will be updated, corrected, changed or deleted, as
              appropriate, as soon as and to the extent reasonably and
              technically practicable. If you are an end user and wish to
              update, delete, or receive any information we have about you, you
              may do so by contacting the organization of which you are a
              customer.
            </Par>
          </Box>
          <Box>
            <Title>Personnel</Title>
            <Par>
              If you are one of our workers or applicants, we collect
              information you voluntarily provide to us. We use the information
              collected for Human Resources purposes in order to administer
              benefits to workers and screen applicants. You may contact us in
              order to (1) update or correct your information, (2) change your
              preferences with respect to communications and other information
              you receive from us, or (3) receive a record of the information we
              have relating to you. Such updates, corrections, changes and
              deletions will have no effect on other information that we
              maintain, or information that we have provided to third parties in
              accordance with this, Privacy Policy prior to such update,
              correction, change or deletion.
            </Par>
          </Box>
          <Box>
            <Title>Sale of Business</Title>
            <Par>
              We reserve the right to transfer information to a third party in
              the event of a sale, merger or other transfer of all or
              substantially all of the assets of us or any of its Corporate
              Affiliates (as defined herein), or that portion of us or any of
              its Corporate Affiliates to which the Service relates, or in the
              event that we discontinue our business or file a petition or have
              filed against us a petition in bankruptcy, reorganization or
              similar proceeding, provided that the third party agrees to adhere
              to the terms of this Privacy Policy.
            </Par>
          </Box>
          <Box>
            <Title>Affiliates</Title>
            <Par>
              We may disclose information (including personal information) about
              you to our Corporate Affiliates. For purposes of this Privacy
              Policy, "Corporate Affiliate" means any person or entity which
              directly or indirectly controls, is controlled by or is under
              common control with us, whether by ownership or otherwise. Any
              information relating to you that we provide to our Corporate
              Affiliates will be treated by those Corporate Affiliates in
              accordance with the terms of this Privacy Policy.
            </Par>
          </Box>
          <Box>
            <Title>How Long Do We Keep Your Information?</Title>
            <Par>
              We keep your information only so long as we need it to provide
              service to you and fulfill the purposes described in this policy.
              This is also the case for anyone that we share your information
              with and who carties out services on our behalf. When we no longer
              need to. use your information and there is no need for us to keep
              it to comply with our legal or regulatory obligations, we'll
              either remove it from our systems or depersonalize it so that we
              can't identify you.
            </Par>
          </Box>
          <Box>
            <Title>How Do We Protect Your Information?</Title>
            <Par>
              We implement a variety of security measures to maintain the safety
              of your personal information when you place an order or enter,
              submit, or access your personal information. We offer the use of a
              secure server. All supplied sensitive/credit information is
              transmitted via Secure Socket Layer (SSL) technology and then
              encrypted into our Payment gateway providers database only to be
              accessible by those authorized with special access rights to such
              systems, and are required to keep the information confidential.
              After a transaction, your private information (credit cards,
              social security numbers, financials, etc.) is never kept on file.
              We cannot, however, ensure or warrant the absolute security of any
              information you transmit to us or guarantee that your information
              on the Service may not be accessed, disclosed, altered, or
              destroyed by a breach of any of our physical, technical, or
              managerial safeguards.
            </Par>
          </Box>
          <Box>
            <Title>Governing Law</Title>
            <Par>
              The laws of Poland, excluding its conflicts of law rules, shall
              govern this Agreement and your use of our service. Your use of our
              service may also be subject to other local, state, national, or
              international laws.
            </Par>
          </Box>
          <Box>
            <Title>Your Consent</Title>
            <Par>
              By using our service, registering an account, or making a
              purchase, you consent to this Privacy Policy.
            </Par>
          </Box>
          <Box>
            <Title>Links to Other Websites</Title>
            <Par>
              This Privacy Policy applies only to the Services. The Services may
              contain links to other websites not operated or controlled by
              Trainbo. We are not responsible for the content, accuracy or
              opinions expressed in such websites, and such websites are not
              investigated, monitored or checked for accuracy or completeness by
              us. Please remember that when you use a link to go from the
              Services to another website, our Privacy Policy is no longer in
              effect. Your browsing and interaction on any other website,
              including those that have a link on our platform, is subject to
              that website’s own rules and policies. Such third parties may use
              their own cookies or other methods to collect information about
              you.
            </Par>
          </Box>
          <Box>
            <Title>Cookies</Title>
            <Par>
              We use "Cookies" to identity the areas of our website that you
              have visited. A Cookie is a small piece of data stored on your
              computer or mobile device by your web browser. We use Cookies to
              personalize the Content that you see on our website. Most web
              browsers can be set to disable the use of Cookies. However, if you
              disable Cookies, you may not be able to access functionality on
              our website correctly or at all. We never place Personally
              Identifiable Information in Cookies.
            </Par>
          </Box>
          <Box>
            <Title>Kids' Privacy</Title>
            <Par>
              We do not address anyone under the age of 13. We do not knowingly
              collect personally identifiable information from anyone under the
              age of 13. If You are a parent or guardian and You are aware that
              Your child has provided Us with Personal Data, please contact Us.
              If We become aware that We have collected Personal Data from
              anyone under the age of 13 without verification of parental
              consent, We take steps to remove that information from our
              servers.
            </Par>
          </Box>
          <Box>
            <Title>Changes To Our Privacy Policy</Title>
            <Par>
              If we decide to change our privacy policy, we will post those
              changes on this page, and/or update the Privacy Policy
              modification date below.
            </Par>
          </Box>
          <Box>
            <Title>Third-Party Services</Title>
            <Par>
              We may display, include or make available third-party content
              (including data, information, applications and other products
              services) or provide links to third-party websites or services
              ("Third- Party Services").
            </Par>
            <Par>
              You acknowledge and agree that we shall not be responsible for any
              Third-Party Services, including their accuracy, completeness,
              timeliness, validity, copyright compliance, legality, decency,
              quality or any other aspect thereof. We do not assume and shall
              not have any liability or responsibility to you or any other
              person or entity for any Third-Party Services.
            </Par>
            <Par>
              Third-Party Services and links thereto are provided solely as a
              convenience to you and you access and use them entirely at your
              own risk and subject to such third parties’ terms and conditions.
            </Par>
          </Box>
          <Box>
            <Title>Facebook Pixel</Title>
            <Par>
              Facebook pixel is an analytics tool that allows you to measure the
              effectiveness of your advertising by understanding the actions
              people take on your website. You can use the pixel to: Make sure
              your ads are shown to the right people. Facebook pixel may collect
              information from your device when you use the service. Facebook
              pixel collects information that is held in accordance with its
              Privacy Policy.
            </Par>
          </Box>
          <Box>
            <Title>Tracking Technologies</Title>
            <Par>
              <ul>
                <li>
                  <p>
                    <b>Cookies</b>
                  </p>
                  We use Cookies to enhance the performance and functionality of
                  our service but are non-essential to their use. However,
                  without these cookies, certain functionality like videos may
                  become unavailable or you would be required to enter your
                  login details every time you visit our service as we would not
                  be able to remember that you had logged in previously.
                </li>

                <li>
                  <p>
                    <b>Local Storage</b>
                  </p>
                  Local Storage sometimes known as DOM storage, provides web
                  apps with methods and protocols for storing client-side data.
                  Web storage supports persistent data storage, similar to
                  cookies but with a greatly enhanced capacity and no
                  information stored in the HTTP request header.
                </li>

                <li>
                  <p>
                    <b>Sessions</b>
                  </p>
                  We use "Sessions" to identify the areas of our website that
                  you have visited. A Session is a small piece of data stored on
                  your computer or mobile device by your web browser.
                </li>
              </ul>
            </Par>
          </Box>
          <Box>
            <Title>
              Information about General Data Protection Regulation (GDPR)
            </Title>
            <Par>
              We may be collecting and using information from you if you are
              from the European Economic Area (EEA), and in this section of our
              Privacy Policy we are going to explain exactly how and why is this
              data collected, and how we maintain this data under protection
              from being replicated or used in the wrong way.
            </Par>
          </Box>
          <Box>
            <Title>What is GDPR?</Title>
            <Par>
              GDPR is an EU-wide privacy and data protection law that regulates
              how EU residents' data is protected by companies and enhances the
              control the EU residents have, over their personal data. 
            </Par>
          </Box>
          <Box>
            <Title>What is personal data?</Title>
            <Par>
              Any data that relates to an identifiable or identified individual.
              GDPR covers a broad spectrum of information that could be used on
              its own, or in combination with other pieces of information, to
              identify a person. Personal data extends beyond a person's name or
              email address. Some examples include financial information,
              political opinions, genetic data, biometric data, IP addresses,
              physical address, sexual orientation, and ethnicity. The Data
              Protection Principles include requirements such as:
            </Par>
            <ul>
              <li>
                Personal data collected must be processed in a fair, legal, and
                transparent way and should only be used in a way that a person
                would reasonably expect.
              </li>
              <li>
                Personal data should only be collected to fultil a specific
                purpose and it should only be used for that purpose.
                Organizations must specify why they need the personal data when
                they collect it.
              </li>
              <li>
                Personal data should be held no longer than necessary to fultil
                its purpose.
              </li>
              <li>
                People covered by the GDPR have the right to access their own
                personal data. They can also request a copy of their data, and
                that their data be updated, deleted, restricted, or moved to
                another organization.
              </li>
            </ul>
          </Box>
          <Box>
            <Title>Why is GDPR important?</Title>
            <Par>
              GDPR adds some new requirements regarding how companies should
              protect individuals’ personal data that they collect and process.
              It also raises the stakes for compliance by increasing enforcement
              and imposing greater fines for breach. Beyond these facts it's
              simply the right thing to do. At Help Scout we strongly believe
              that your data privacy is very important and we already have solid
              security and privacy practices in place that go beyond the
              requirements of this new regulation.
            </Par>
          </Box>
          <Box>
            <Title>
              Individual Data Subject's Rights - Data Access, Portability and
              Deletion
            </Title>
            <Par>
              We are committed to helping our customers meet the data subject
              rights requirements of GDPR. We process or store all personal data
              in fully vetted, DPA compliant vendors. We do store all
              conversation and personal data for up to 6 years unless your
              account is, deleted. In which case, we dispose of all data in
              accordance with our Terms of Service and Privacy Policy, but we
              will not hold it longer than 60 days.
            </Par>
            <Par>
              We are aware that if you are working with EU customers, you need
              to be able to provide them with the ability to access, update,
              retrieve and remove personal data. We got you! We've been set up
              as self service from the start and have always given you access to
              your data and your customers data. Our customer support team is
              here for you to answer any questions you might have about working
              with the API. 
            </Par>
          </Box>
          <Box>
            <Title>California Residents</Title>
            <Par>
              The California Consumer Privacy Act (CCPA) requires us to disclose
              categories of Personal Information we collect and how we use it,
              the categories of sources from whom we collect Personal
              Information, and the third parties with whom we share it, which we
              have explained above.
            </Par>
            <Par>
              We are also required to communicate information about rights
              California residents have under California law. You may exercise
              the following rights:
            </Par>
            <ul>
              <li>
                Right to Know and Access. You may submit a verifiable request
                for information regarding the: (1) categories of Personal
                Information we collect, use, or share; (2) purposes for which
                categories of Personal Information are collected or used by us;
                (3) categories of sources from which we collect Personal
                Information; and (4) specific pieces of Personal Information we
                have collected about you.
              </li>
              <li>
                Right to Equal Service. We will not discriminate against you if
                you exercise your privacy rights.
              </li>
              <li>
                Right to Delete. You may submit a verifiable request to close
                your account and we will delete Personal Information about you
                that we have collected.
              </li>
              <li>
                Request that a business that sells a consumer's personal data,
                not sell the consumer's personal data.
              </li>
            </ul>
            <Par>
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </Par>
            <Par>We do not sell the Personal Information of our users. </Par>

            <Par>
              For more information about these rights, please contact us.
            </Par>
          </Box>
          <Box>
            <Title></Title>
            <Par>
              CalOPPA requires us to disclose categories of Personal Information
              we collect and how we use it, the categories of sources from whom
              we collect Personal Information, and the third parties with whom
              we share it, which we have explained above.
            </Par>
            <Par>CalOPPA users have the following rights:</Par>
            <ul>
              <li>
                Right to Know and Access. You may submit a verifiable request
                for information regarding the: (1) categories of Personal
                Information we collect, use, or share; (2) purposes for which
                categories of Personal Information are collected or used by us;
                (3) categories of sources from which we collect Personal
                Information; and (4) specific pieces of Personal Information we
                have collected about you.
              </li>
              <li>
                Right to Equal Service. We will not discriminate against you if
                you exercise your privacy rights.
              </li>
              <li>
                Right to Delete. You may submit a verifiable request to close
                your account and we will delete Personal Information about you
                that we have collected.
              </li>
              <li>
                Right to request that a business that sells a consumer's
                personal data, not sell the consumer's personal data.
              </li>
              <li>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </li>
            </ul>
            <Par>We do not sell the Personal Information of our users.</Par>
            <Par>
              For more information about these rights, please contact us.
            </Par>
          </Box>
          <Box>
            <Title>Contact Us</Title>
            <Par>Don't hesitate to contact us if you have any questions.</Par>
            <ul>
              <li>Via Email: contact@trainbo.com</li>
            </ul>
          </Box>
        </Body>
      </Container>
    </>
  );
};

export default Privacy;
