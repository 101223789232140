import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../components/header/Header";
import { Helmet } from "react-helmet";
import { IoCloseOutline, IoCheckmarkCircleOutline } from "react-icons/io5";
import Colors from "../../shared/colors";

import * as authActions from "../../store/actions/auth";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 250px);
`;

const Box = styled.div`
  display: block;
  text-align: center;
  width: 500px;
  padding: 0 50px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 10px;
  }
`;

const Title = styled.h1``;

const Text = styled.p`
  color: ${Colors.secondaryText};
`;

const DeleteAccount = () => {
  const { token } = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    dispatch(authActions.deleteAccount(token))
      .then((res) => {
        setErrorState(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorState(true);
        setIsLoading(false);
      });
  }, [token, dispatch]);

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>Activate your Trainbo accout</title>
        <meta name='robots' content='noindex' />
        <meta name='googlebot' content='noindex' />
      </Helmet>
      <Header />
      <Container>
        {errorState ? (
          <Box>
            <IoCloseOutline style={{ fontSize: 100 }} />
            <Title>Your delete account code has expired</Title>
            <Text>If you need help: help@trainbo.com</Text>
          </Box>
        ) : (
          <Box>
            <IoCheckmarkCircleOutline
              style={{ fontSize: 100, color: `${Colors.green}` }}
            />
            <Title>Your trainbo account has been deleted</Title>
            <Text>If you need help: help@trainbo.com</Text>
          </Box>
        )}
      </Container>
    </>
  );
};

export default DeleteAccount;
