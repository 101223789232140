export default {
  borderColorBase: "#1B2434",
  borderColorCompontent: "#2C384E",
  bodyBackground: "#000",
  darkBackground: "#090b0f",
  inputBackground: "#0d131f",
  componentBackground: "#1B2434",
  inputOnBackground: "#121D25",
  exerciseOnPlanBg: "#2E323E",
  secondaryText: "#828ea1",
  thirdText: "#314553",
  primary: "#355DFF",
  danger: "#FF4040",
  dangerActive: "#FF5A5A",
  tableBorder: "#131f28",
  green: "#01FF48",
};
