import api from "../../shared/api";

export const deleteAccount = (token) => {
  return async (dispatch) => {
    try {
      const response = await api.get(`/user/deleting-account/${token}`);
      console.log(response);
      return "ok";
    } catch (err) {
      throw new Error("Error, try again.");
    }
  };
};

export const changePassword = (token, password) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`/user/new-password/${token}`, {
        password: password,
      });
      console.log(response);
      return "ok";
    } catch (err) {
      throw new Error("Error, try again.");
    }
  };
};
