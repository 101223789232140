import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../components/header/Header";
import { Helmet } from "react-helmet";
import { IoCheckmarkCircleOutline, IoEye, IoEyeOff } from "react-icons/io5";
import Colors from "../../shared/colors";

import * as authActions from "../../store/actions/auth";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 250px);
`;

const Box = styled.div`
  display: block;
  text-align: center;
  width: 500px;
  padding: 0 50px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 10px;
  }
`;

const Title = styled.h1``;

const Text = styled.p`
  color: ${Colors.secondaryText};
`;

const TextMin = styled.p`
  color: ${Colors.secondaryText};
  font-size: 14px;
  text-align: center;
`;

const TextError = styled.p`
  color: #ff4444;
`;

const InputBox = styled.div`
  padding: 10px;
  text-align: left;
`;

const InputLabel = styled.label`
  margin-bottom: 10px;
  display: block;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  background: #232323;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  outline: none;
  border-radius: 8px;
  border: 0;
`;

const InputSubmit = styled.input`
  width: 100%;
  height: 51px;
  padding: 15px;
  background: #01ff48;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  border: 0;
  cursor: pointer;
  border-radius: 8px;
`;

const InputContainer = styled.div`
  width: 100%;
  background: #232323;
  display: flex;
  align-items: stretch;
  border-radius: 8px;
`;

const IconContainer = styled.div`
  width: 50px;
  min-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
`;

const ChangePassword = () => {
  const { token } = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [webState, setWebState] = useState(false);
  const [errorForm, setErrorFormState] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    console.log(password);
    console.log(password2);

    if (password !== password2) {
      setErrorFormState(true);
      setErrorMsg("Your password and confirmation password do not match.");
      return;
    }

    if (password.length < 8) {
      setErrorFormState(true);
      setErrorMsg("The password must be at least 8 characters.");
      return;
    }

    dispatch(authActions.changePassword(token, password))
      .then((res) => {
        console.log("cahnged");
        setWebState(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorFormState(true);
        setErrorMsg("Your code has expired. Try reset password again.");
        setIsLoading(false);
      });
  };

  const showPasswordhandler = () => {
    setShowPassword((prev) => !prev);
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>Change your password</title>
        <meta name='robots' content='noindex' />
        <meta name='googlebot' content='noindex' />
      </Helmet>
      <Header />
      <Container>
        {webState ? (
          <Box>
            <IoCheckmarkCircleOutline
              style={{ fontSize: 100, color: "#01ff48" }}
            />
            <Title>Your password has been changed</Title>
            <Text>Back to trainbo and login.</Text>
          </Box>
        ) : (
          <Box>
            <Title>Create new password</Title>

            {errorForm ? <TextError>{errorMsg}</TextError> : ""}

            <form method='post' onSubmit={onSubmitHandler}>
              <InputBox>
                <InputLabel>New password:</InputLabel>
                <InputContainer>
                  <Input
                    name='password'
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <IconContainer onClick={showPasswordhandler}>
                    {showPassword ? (
                      <IoEyeOff style={{ fontSize: 26, color: "#999" }} />
                    ) : (
                      <IoEye style={{ fontSize: 26, color: "#999" }} />
                    )}
                  </IconContainer>
                </InputContainer>
              </InputBox>
              <InputBox>
                <InputLabel>Confirm password:</InputLabel>
                <InputContainer>
                  <Input
                    name='password2'
                    type={showPassword ? "text" : "password"}
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                  <IconContainer onClick={showPasswordhandler}>
                    {showPassword ? (
                      <IoEyeOff style={{ fontSize: 26, color: "#999" }} />
                    ) : (
                      <IoEye style={{ fontSize: 26, color: "#999" }} />
                    )}
                  </IconContainer>
                </InputContainer>
                <TextMin>Minimum 8 characters required</TextMin>
              </InputBox>
              <InputBox>
                <InputSubmit type='submit' value='Change password' />
              </InputBox>
            </form>
          </Box>
        )}
      </Container>
    </>
  );
};

export default ChangePassword;
